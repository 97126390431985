import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  AIFlaggedIcon,
  UserFlaggedIcon,
  UserIgnoredIcon,
  NotFlaggedIcon,
} from './CheckboxIcons';
import { BRAND_SAFETY_LABELS } from './brandSafetyConsts';

export const NOT_FLAGGED = 'NOT_FLAGGED';
export const AI_FLAGGED = 'AI_FLAGGED';
export const USER_FLAGGED = 'USER_FLAGGED';
export const USER_IGNORED = 'USER_IGNORED';

export default function BrandSafetyCheckbox({
  flagKey,
  initialState,
  onChange,
}) {
  const [status, setStatus] = useState(initialState);
  const [displayStatus, setDisplayStatus] = useState(initialState);

  useEffect(() => {
    setStatus(initialState);
    setDisplayStatus(initialState);
  }, [initialState]);

  const icons = {
    NOT_FLAGGED: <NotFlaggedIcon />,
    USER_FLAGGED: <UserFlaggedIcon />,
    USER_IGNORED: <UserIgnoredIcon />,
    AI_FLAGGED: <AIFlaggedIcon />,
  };

  const getNextStatus = (currentStatus, event) => {
    let newStatus = NOT_FLAGGED;
    switch (currentStatus) {
      case NOT_FLAGGED:
        newStatus = USER_FLAGGED;
        break;
      case AI_FLAGGED:
        newStatus = USER_IGNORED;
        break;
      case USER_IGNORED:
        newStatus = AI_FLAGGED;
        break;
      case USER_FLAGGED:
        if (event === 'hover') {
          newStatus = USER_IGNORED;
        } else {
          newStatus = NOT_FLAGGED;
        }
        break;
      default:
        break;
    }
    return newStatus;
  };

  return (
    <div className="brand-safety-checkbox-wrapper">
      <button
        type="button"
        onClick={() => {
          const newStatus = getNextStatus(status, 'click');
          setStatus(newStatus);
          onChange(flagKey, newStatus);
        }}
      >
        {icons[displayStatus]}
      </button>
      {BRAND_SAFETY_LABELS[flagKey]}
    </div>
  );
}

BrandSafetyCheckbox.propTypes = {
  initialState: PropTypes.string,
  flagKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

BrandSafetyCheckbox.defaultProps = {
  initialState: NOT_FLAGGED,
  onChange: () => {},
};
