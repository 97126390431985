export const BRAND_SAFETY_INITIAL_FLAGS = {
  AlcoholFlag: 0,
  SmokingFlag: 0,
  NarcoticsFlag: 0,
  MarijuanaFlag: 0,
  TherapeuticsFlag: 0,
  KnivesFlag: 0,
  GunsFlag: 0,
  HateFlag: 0,
  PoliticsFlag: 0,
  ReligionFlag: 0,
  AdultFlag: 0,
  TrademarkFlag: 0,
};

export const BRAND_SAFETY_LABELS = {
  AlcoholFlag: 'Alcohol',
  SmokingFlag: 'Tobacco / Nicotine',
  NarcoticsFlag: 'Narcotics',
  MarijuanaFlag: 'Marijuana',
  TherapeuticsFlag: 'Therapeutics Drugs',
  KnivesFlag: 'Knives',
  GunsFlag: 'Guns',
  HateFlag: 'Hate Content',
  PoliticsFlag: 'Political Content',
  ReligionFlag: 'Religious Content',
  AdultFlag: 'Adult Content',
  TrademarkFlag: 'Trademark Detection',
  DepictingViolenceFlag: 'Depicting Violence',
  ThirdPartyContentFlag: '3rd Party Content',
};

export const BRAND_SAFETY_CATEGORIES = [
  {
    name: 'Identify Harmful Substance',
    items: [
      'AlcoholFlag',
      'SmokingFlag',
      'NarcoticsFlag',
      'MarijuanaFlag',
      'TherapeuticsFlag',
    ],
  },
  {
    name: 'Identify Harmful Objects',
    items: ['KnivesFlag', 'GunsFlag'],
  },
  {
    name: 'Potentially Offensive Content',
    items: [
      'HateFlag',
      'PoliticsFlag',
      'ReligionFlag',
      'DepictingViolenceFlag',
    ],
  },
  {
    name: 'Legal And Ethical Issues',
    items: ['ThirdPartyContentFlag', 'TrademarkFlag'],
  },
];

export const BRAND_SAFETY_MEASURE_STATUS = {
  ACCEPTED: 'Reviewed & Accepted',
  FLAGGED: 'Reviewed & Flagged',
  NOT_REVIEWED: 'Not Reviewed',
  IGNORED: 'Ignored Flags',
  REMOVED: 'Removed Flags',
};
