import React from 'react';

export function NotFlaggedIcon() {
  return <div className="brand-safety-checkbox" />;
}

export function AIFlaggedIcon() {
  return (
    <div className="brand-safety-checkbox">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.54772 6.45537C5.15684 3.60807 5.9614 2.18442 7.06543 1.81796C7.67279 1.61635 8.32704 1.61635 8.93441 1.81796C10.0384 2.18442 10.843 3.60807 12.4521 6.45537C14.0612 9.30266 14.8658 10.7263 14.6244 11.8863C14.4917 12.5245 14.1645 13.1033 13.6899 13.5399C12.8273 14.3334 11.2182 14.3334 7.99992 14.3334C4.78168 14.3334 3.17257 14.3334 2.30989 13.5399C1.8353 13.1033 1.50818 12.5245 1.3754 11.8863C1.13405 10.7263 1.93861 9.30266 3.54772 6.45537Z"
          stroke="#EB6137"
        />
        <path
          d="M7.9945 10.6667H8.00049"
          stroke="#EB6137"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8.66675L8 6.00008"
          stroke="#EB6137"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export function UserFlaggedIcon() {
  return (
    <div className="brand-safety-checkbox user-flagged ">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13817_27677)">
          <path
            d="M3.33325 9.33325L5.66659 11.6666L12.6666 4.33325"
            stroke="#141B34"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_13817_27677">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export function UserIgnoredIcon() {
  return (
    <div className="brand-safety-checkbox user-ignored ">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6671 3.33325L3.33374 12.6666M3.33374 3.33325L12.6671 12.6666"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
