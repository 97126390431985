/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import { USER_FLAGGED, USER_IGNORED } from './BrandSafetyCheckbox';
import { BRAND_SAFETY_MEASURE_STATUS } from './brandSafetyConsts';

export function parseUserFeedbackRecord(record) {
  if (!record) {
    return null;
  }

  let notes = [];
  let flagFeedback = {};
  try {
    notes = JSON.parse(record.notes);
  } catch (e) {}

  try {
    flagFeedback = JSON.parse(record.flagFeedback);
  } catch (e) {}

  return {
    ...record,
    notes,
    flagFeedback,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function formatBranSafetyData(
  influencerList,
  userFeedbackData,
  campaignBrandSafetyPreference
) {
  const flaggedPostList = [];

  influencerList?.forEach(infData => {
    infData.flaggedPosts?.forEach(post => {
      const resPost = {
        creatorReportDetailsId: infData.creatorReportDetailsId,
        postDetails: post.postDetails,
        creatorPostId: post.creatorPostId,
      };

      // flags that are ignored at campaign level
      const campaignIgnoredFlags = [];

      // flags that are on in the preference setting & is not removed by user
      const activeFlags = [];

      // flags that are removed by user
      const userRemovedFlags = [];

      const userFeedbackRecord = userFeedbackData.find(
        record => record.creatorPostId === post.creatorPostId
      );

      post.flags.forEach(flagObj => {
        const flagKey =
          flagObj.type.charAt(0).toUpperCase() + flagObj.type.slice(1);

        if (userFeedbackRecord?.flagFeedback[flagKey] === USER_IGNORED) {
          userRemovedFlags.push(flagKey);
          return;
        }

        if (campaignBrandSafetyPreference[flagKey]) {
          activeFlags.push(flagKey);
        } else {
          campaignIgnoredFlags.push(flagKey);
        }
      });

      // append user added flags
      _.entries(userFeedbackRecord?.flagFeedback).forEach(([flag, value]) => {
        if (value === USER_FLAGGED && campaignBrandSafetyPreference[flag]) {
          activeFlags.push(flag);
        }
      });

      const reviewStatus =
        userFeedbackRecord?.status || BRAND_SAFETY_MEASURE_STATUS.NOT_REVIEWED;

      resPost.flagDetails = post.flags;
      resPost.activeFlags = activeFlags;
      resPost.campaignIgnoredFlags = campaignIgnoredFlags;
      resPost.userFeedback = userFeedbackRecord;
      resPost.reviewStatus = reviewStatus;
      resPost.userRemovedFlags = userRemovedFlags;

      flaggedPostList.push(resPost);
    });
  });

  // console.log(influencerList);
  // console.log(userFeedbackData);
  // console.log(campaignBrandSafetyPreference);
  // console.log(flaggedPostList);

  return flaggedPostList;
}

export function getPostsByFilter(posts, filter) {
  if (!filter) {
    return posts;
  }

  if (filter === BRAND_SAFETY_MEASURE_STATUS.IGNORED) {
    return posts.filter(post => post.campaignIgnoredFlags.length > 0);
  }

  if (filter === BRAND_SAFETY_MEASURE_STATUS.REMOVED) {
    return posts.filter(post => post.userRemovedFlags.length > 0);
  }

  return posts.filter(post => post.reviewStatus === filter);
}

export function extractCountTotals(brandSafetyData) {
  const summary = {};

  brandSafetyData.forEach(item => {
    const { summaryData } = item;

    if (_.isNil(summaryData)) {
      return;
    }

    /// add up summary data
    _.entries(summaryData).forEach(([key, value]) => {
      if (!summary[key]) {
        summary[key] = 0;
      }
      summary[key] += value;
    });
  });

  return summary;
}

export function calculateSummaryData(flaggedPosts) {
  let postsCountFlagged = 0;
  let videoCountFlagged = 0;
  let imageCountFlagged = 0;
  let textCountFlagged = 0;

  const creatorSet = new Set();

  flaggedPosts.forEach(post => {
    let videoFlagged = false;
    let imageFlagged = false;
    let textFlagged = false;
    let postFlagged = false;
    if (post.activeFlags.length === 0) {
      return;
    }

    post.flagDetails.forEach(flag => {
      if (post.reviewStatus === BRAND_SAFETY_MEASURE_STATUS.ACCEPTED) {
        return;
      }

      const flagKey = flag.type.charAt(0).toUpperCase() + flag.type.slice(1);

      // ignore flag that is off in the setting
      if (!post.activeFlags.includes(flagKey)) {
        return;
      }

      // check for flag existence
      videoFlagged = _.keys(flag.details).some(key => {
        return /^video[a-zA-Z]+Flag$/.test(key);
      });

      imageFlagged = _.keys(flag.details).some(key => {
        return /^image[a-zA-Z]+Flag$/.test(key);
      });

      textFlagged = _.keys(flag.details).some(key => {
        return /^text[a-zA-Z]+Flag$/.test(key);
      });
    });

    if (videoFlagged) {
      videoCountFlagged += 1;
    }

    if (imageFlagged) {
      imageCountFlagged += 1;
    }

    if (textFlagged) {
      textCountFlagged += 1;
    }

    if (videoFlagged || imageFlagged || textFlagged) {
      postFlagged = true;
    }

    if (post.userFeedback) {
      const { flagFeedback = {} } = post.userFeedback;

      _.entries(flagFeedback).forEach(([flag, value]) => {
        if (
          value === USER_FLAGGED &&
          !post.campaignIgnoredFlags.includes(flag)
        ) {
          postFlagged = true;
        }
      });
    }

    if (postFlagged) {
      postsCountFlagged += 1;
      creatorSet.add(post.creatorReportDetailsId);
    }
  });

  return {
    postsCountFlagged,
    videoCountFlagged,
    imageCountFlagged,
    textCountFlagged,
    creatorCountFlagged: creatorSet.size,
  };
}

export function getDisplayFlags(filter, post) {
  if (!post) {
    return [];
  }
  if (filter === BRAND_SAFETY_MEASURE_STATUS.IGNORED) {
    return [...post.campaignIgnoredFlags, ...post.activeFlags];
  }

  if (filter === BRAND_SAFETY_MEASURE_STATUS.REMOVED) {
    return post.userRemovedFlags;
  }

  return post.activeFlags;
}

export function getAllIBrandSafetyIssueTypes(details) {
  const allTypes = details.map(detail => {
    if (detail.includes('text')) {
      return 'text';
    }

    if (detail.includes('image')) {
      return 'image';
    }

    if (detail.includes('video')) {
      return 'video';
    }

    if (detail.includes('post')) {
      return 'post';
    }

    return '';
  });

  if (allTypes.length === 1) return allTypes[0];
  if (allTypes.length === 2) return allTypes.join(' and ');

  return `${allTypes.slice(0, -1).join(', ')} and ${
    allTypes[allTypes.length - 1]
  }`;
}
